<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="mb-3">
						<h5 class="font-semibold m-0">Pilih Master Data</h5>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" class="mb-3">
						<a-radio-group v-model="selected_master" size="small">
							<a-radio-button value="service">SERVICE</a-radio-button>
							<a-radio-button value="location">KOTA</a-radio-button>
							<a-radio-button value="regency">KABUPATEN</a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</template>
		</a-card>
		<br/>

		<!-- Location Table -->
		<a-row :gutter="24" type="flex" v-if="selected_master == 'location'">

			<!-- Location Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Location Table Card -->
				<CardLocationTable></CardLocationTable>
				<!-- / Location Table Card -->

			</a-col>
			<!-- / Location Table Column -->

		</a-row>
		<!-- / Location Table -->

		<!-- Pricing Table -->
		<a-row :gutter="24" type="flex" v-if="selected_master == 'regency'">

			<!-- Pricing Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Pricing Table Card -->
				<CardRegencyTable></CardRegencyTable>
				<!-- / Pricing Table Card -->

			</a-col>
			<!-- / Pricing Table Column -->

		</a-row>
		<!-- / Pricing Table -->

		<!-- user Table -->
		<a-row :gutter="24" type="flex" v-if="selected_master == 'service'">

			<!-- user Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- user Table Card -->
				<CardServiceTable></CardServiceTable>
				<!-- / user Table Card -->

			</a-col>
			<!-- / user Table Column -->

		</a-row>
		<!-- / user Table -->

	</div>
</template>

<script>

	// "Authors" table component.
	import CardRegencyTable from '../components/Cards/CardRegencyTable' ;
	import CardServiceTable from '../components/Cards/CardServiceTable' ;
	import CardLocationTable from '../components/Cards/CardLocationTable' ;

	export default ({
		components: {
			CardRegencyTable, CardServiceTable, CardLocationTable
		},
		data() {
			return {
				selected_master: 'service',
			}
		},
	})

</script>

<style lang="scss">
</style>