<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<loading :active.sync="isLoading" :is-full-page="true"></loading>
		<div v-if="alert.msg">
			<a-alert :message="alert.msg" :type="alert.type" closable></a-alert>
		</div>
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12" class="mb-3">
					<h5 class="font-semibold m-0">Daftar Kota</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" class="mb-3">

					<a-button type="primary" @click="onClickCreateNew()" size="small" style="margin-right: 5px;">
						<a-icon type="plus-square" theme="outlined" />
					    Buat Data
					</a-button>
					<a-button @click="showModal = true" size="small">
						<a-icon type="funnel-plot" theme="outlined" />
					    Filter Data
					</a-button>
				</a-col>
			</a-row>
		</template>
		<a-modal v-model="showModal" title="Filter Data" @ok="onSubmitFilter">
			<div class="mb-3">
	      		<a-input v-model="keyword" placeholder="Masukkan kata kunci ..." />
				<small>Nama kota</small>
			</div>
	    </a-modal>
	    <a-modal v-model="showModalCreate" :title="selected_row ? 'Ubah Data' : 'Buat Data'" @ok="onSubmitForm">
			<div class="mb-3">
	      		<a-input v-model="form.name" placeholder="Masukkan nama kota" />
	      		<a-alert class="mt-1" type="error" :message="validation.name" show-icon v-if="validation.name"></a-alert>
			</div>
	    </a-modal>
	    <a-modal v-model="showModalDelete" title="Hapus Data" @ok="onSubmitDelete">
			<div class="mb-3">
				<h6>Kota: {{selected_row.name}}</h6>
	      		<a-alert message="Anda akan menghapus kota yang dipilih dan seluruh harga yang bersangkutan dengan kota ini. Apakah anda yakin?" type="error" />
			</div>
	    </a-modal>
		<a-table :columns="columns" :data-source="data" :pagination="false">

			<template slot="name" slot-scope="name">
				<div class="author-info">
					<h6 class="m-0">{{ name }}</h6>
				</div>
			</template>

			<template slot="editBtn" slot-scope="row">
				<a-button type="primary" size="small" :data-id="row.key" @click="onUpdate(row)">
					<a-icon type="edit" theme="outlined" />
					Edit
				</a-button>&nbsp;
				<a-button type="danger" size="small" ghost :data-id="row.key" @click="onDelete(row)">
					<a-icon type="delete" theme="outlined" />
					Hapus
				</a-button>
			</template>

		</a-table>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>

		// "Authors" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'NAMA KOTA',
			dataIndex: 'name',
			scopedSlots: { customRender: 'name' },
		},
		{
			title: '',
			scopedSlots: { customRender: 'editBtn' },
			width: 250,
		},
	];

	import { processMessage, setInitialValue } from '../../helpers';
	import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

	export default ({
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
				data: [],
				keyword: '',
				columns: table1Columns,
				showModal: false,
				showModalCreate: false,
				showModalDelete: false,
				selected_row: {},
				alert: {
					type: '',
					msg: ''
				},
				form: {
					name: ''
				},
				validation: {
					name: ''
				},
				isLoading: false
			}
		},
		components: { Loading },
		mounted(){
			this.retrieveLocationList()
		},
	 	methods: {
	 		retrieveLocationList(){
	 			this.isLoading = true
	 			this.$http.get(process.env.VUE_APP_API + '/location?keyword=' + this.keyword)
				.then((res) => {
					this.data = res.data.data
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
				.finally(() => {
					this.isLoading = false
				})
	 		},
			onSubmitFilter(e){
      			this.showModal = false;
				this.retrieveLocationList();
			},
			onDelete(row){
				this.showModalDelete = true 
				this.selected_row = row
			},
			onClickCreateNew(){
				setInitialValue(this.validation)

				this.showModalCreate = true;
				this.form.name = ''
				this.selected_row = ''
				this.type = 'create'
			},
			onUpdate(row){
				setInitialValue(this.validation)

				this.selected_row = row
				this.form.name = this.selected_row.name
				this.type = 'update'
				this.showModalCreate = true
			},
			onSubmitForm(e){
				e.preventDefault();

				setInitialValue(this.validation)

				if(!this.form.name){
					this.validation.name = 'Masukkan nama kota'
					return ;
				}
				
				let url = process.env.VUE_APP_API + '/location'

				if(this.type == 'update'){
					url = process.env.VUE_APP_API + '/location/' + this.selected_row.id
				}

				this.$http.post(url, {
					name: this.form.name,
					_method: this.type == 'update' ? 'PUT' : 'POST'
				})
				.then((res) => {
					console.log(res)
					this.alert = processMessage(res)
					this.showModalCreate = false
					this.retrieveLocationList()
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
			},
			onSubmitDelete(){
				this.$http.delete(process.env.VUE_APP_API + '/location/' + this.selected_row.id)
				.then((res) => {
					this.alert = processMessage(res)
					this.showModalDelete = false
					this.retrieveLocationList()
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
			},
		}
	})

</script>